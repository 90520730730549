import ReactGA from "react-ga";

export function initializeReactGA() {
  ReactGA.initialize("UA-146476544-1");
}

export function pageviewHome() {
  ReactGA.pageview("/");
}

export function eventClickAndroid() {
  if (process.env.NODE_ENV !== "development") {
    ReactGA.event({
      category: "Links",
      action: "Click Android"
    });
  }
}

export function eventClickIPhone() {
  if (process.env.NODE_ENV !== "development") {
    ReactGA.event({
      category: "Links",
      action: "Click Android"
    });
  }
}

export function eventClickPrivacy() {
  if (process.env.NODE_ENV !== "development") {
    ReactGA.event({
      category: "Links",
      action: "Click Privacy Policy"
    });
  }
}

export function eventClickTrevor() {
  if (process.env.NODE_ENV !== "development") {
    ReactGA.event({
      category: "Links",
      action: "Click Trevor"
    });
  }
}
