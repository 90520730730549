import React from "react";
import "./Thin.css";
import "./App.css";
import Massage from "./Massage";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { initializeReactGA, pageviewHome } from "./analytics";
import "./quick-split-1200.png";

initializeReactGA();

const App: React.FC = () => {
  const [isMounted, setIsMounted] = React.useState(false);
  React.useEffect(() => {
    pageviewHome();
    setIsMounted(true);
  }, []);

  return (
    <Router>
      <div style={{ opacity: isMounted ? 1 : 0, transition: "all 1.5s ease" }}>
        <Switch>
          <Route exact path="/">
            <Massage />
          </Route>

          <Redirect to="/" />
        </Switch>
      </div>
    </Router>
  );
};

export default App;
