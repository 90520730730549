import * as React from "react";
import Slider from "react-slick";
import "./Slider.css";

import Demo1 from "./demo-1.png";
import Demo2 from "./demo-2.png";
import Demo3 from "./demo-3.png";
import Demo4 from "./demo-4.png";

export default function PreviousNextMethods({
  setRefSlider
}: {
  // @ts-ignore
  setRefSlider: Function;
}) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <div>
      {/* 
        // @ts-ignore */}
      <Slider
        ref={c => {
          setRefSlider(c);
        }}
        {...settings}
      >
        <div key={1}>
          <img src={Demo1} />
        </div>
        <div key={2}>
          <img src={Demo4} />
        </div>
        <div key={3}>
          <img src={Demo2} />
        </div>
        <div key={4}>
          <img src={Demo3} />
        </div>
        {/* <div key={4}>
          <h4>4</h4>
        </div>
        <div key={5}>
          <h4>5</h4>
        </div>
        <div key={6}>
          <h4>6</h4>
        </div> */}
      </Slider>
    </div>
  );
}
