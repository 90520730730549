import * as React from "react";
import PreviousNextMethods from "./Slider";

import PhoneCase from "./phone-case.png";
import AppleStore from "./Apple Store Dark.png";
import GoogleStore from "./Google Play Dark.png";
import AppIcon from "./quick-split-app-icon.jpg";
import LeftArrow from "./arrow-left.png";
import RightArrow from "./arrow-right.png";
import {
  eventClickTrevor,
  eventClickPrivacy,
  eventClickIPhone
} from "./analytics";

export default function App() {
  const [RefSlider, setRefSlider] = React.useState(null);

  function next() {
    // @ts-ignore
    RefSlider.slickNext();
  }
  function previous() {
    // @ts-ignore
    RefSlider.slickPrev();
  }

  return (
    <div id="Home" className="big-container">
      <section
        className="banner-section"
        style={{
          backgroundColor: "#266ef1",
          transition: "all .3s ease"
        }}
      >
        <div
          className="banner-wrap"
          style={{
            transition: "all .3s ease"
          }}
        >
          <img className="header-icon" src={AppIcon} />
        </div>
      </section>
      <section id="slider-section" className="slider-section">
        <div className="slider-content">
          <div className="slider-inner">
            <div className="slider-holder">
              <div
                className="slider w-slider"
                style={{ backgroundImage: `url(${PhoneCase}) !important` }}
              >
                <div id="innerPhoneContainer">
                  <PreviousNextMethods setRefSlider={setRefSlider} />
                </div>
                <div
                  className="w-slider-arrow-left slider-button slider-button-left"
                  onClick={previous}
                >
                  <img src={LeftArrow} />
                </div>
                <div
                  className="w-slider-arrow-right slider-button slider-button-right"
                  onClick={next}
                >
                  <img src={RightArrow} />
                </div>
                <div className="slide-nav w-slider-nav"></div>
              </div>
            </div>
            <div className="slider-text-wrap">
              <h3
                style={{ color: "black" }}
              >{`The fairest way to split the bill`}</h3>
              <a
                className="no-margin-bottom"
                href={`https://apps.apple.com/us/app/split-bill-quick/id1474896987`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={eventClickIPhone}
              >
                <img className="store-link" src={AppleStore} />
                {/* <img className="store-link" src={GoogleStore} /> */}
              </a>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer-section">
        <div className="footer-content">
          <div style={{ marginBottom: 20 }}>
            <div
              style={{
                textAlign: "center",
                color: "white"
              }}
            >
              {`No Ads. Free. Easy.`}
            </div>
            <div
              style={{
                textAlign: "center",
                color: "white"
              }}
            >
              <span>
                Created by
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://trevorhealy.me"
                  className="underline-link"
                  style={{ marginLeft: 5 }}
                  onClick={eventClickTrevor}
                >
                  Trevor Healy
                </a>
              </span>
            </div>
          </div>

          <div className="footer-links-wrap">
            <div className="footer-link-block">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://app.termly.io/document/privacy-policy/06b30d1f-b3eb-43ba-9f13-6e9795b36273"
                className="underline-link"
                onClick={eventClickPrivacy}
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
